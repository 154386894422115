.contact-us-big-container {
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: var(--font-family);
    color: #fff;
    margin: 15% auto 5%;
}

.carrier__disc{
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.div__guur ul li{
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.contact-us-small-container {
    width: 70%;
    height: auto;
    background-color: var(--color-reason);
    margin: auto;
    border-radius: 50px;
    padding: 50px;
}


.div__guur h1 {
    font-size: 40px;
    font-family: var(--font-family2);
    color: #fff;
}

.dot {
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    margin-right: 0.5rem;
    border-radius: 50px;

}



.div__guur {
    margin: 40px 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 50px;
}


.carrier__list{
    margin: auto;
    width: 80%;
    height: auto;
}
.carrier__list__item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-items: flex-start;

}

.carrier__list__header{
    margin-bottom: 20px;
    font-size: 30px;
}

.carrier__wilkommen{
    font-size: 20px;
}

.carrier__disc {
    /* Your existing styles for desktop */
  
    /* Hide on mobile devices */
    @media (max-width: 767px) {
      display: none;
    }
  }
  
@media screen and ( max-width: 460px) {
    .contact-us-big-container {
        margin: 25% auto 10%;
        height: auto;
    }
    .contact-us-small-container {
        width: 80%;

    
    }
    
    .div__guur {
        margin: 30px 10px 80px 10px;
    }

    .div__guur h1 {
        font-size: 20px;
        margin-bottom: 1rem;
        
    }
    
}

@media screen and ( max-width: 414px) {
    .contact-us-big-container {
        margin: 25% auto 10%;
        height: auto;
    }
    .contact-us-small-container {
        width: 80%;

    
    }

    .div__guur {
        margin: 30px 10px 100px 10px;
    }
    .div__guur h1 {
        font-size: 20px;
        margin-bottom: 1rem;
    }
    
    .div__guur p {
        font-size: 16px;
    }

}