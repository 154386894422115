.guur__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-footer);
    color: var(--color-footer-text);
}

.guur__footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    width: 100%;
    text-align: left;
    margin: 45px 0px;
}

.guur__footer-bottom {
    font-size: 16px;
    margin-bottom: 50px;
    font-family: var(--font-family2);
}

.guur__footer-hr {
    background: rgba(153, 160, 187, 0.2);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);   
    border-radius: 2px;
    opacity: 20%;
    width: 80%;
    margin-bottom: 50px;
}

.guur__footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    padding: 2rem;
    font-family: var(--font-family2);
    font-size: 14px;
}  


.guur__footer-link_style a:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    cursor: pointer;
  }
  

.guur__footer-socials {
    padding: 2rem;
    margin-right: 9px;
    margin-left: 31px;
}

.guur__footer-socials a {
    display: inline-block;
    margin-right: 22px;
}
  
.guur__footer-socials img {
    filter: brightness(100%);
    transition: all 0.3s ease-out;
}
  
  .guur__footer-socials img:hover {
    filter: brightness(30%);
}
  
.guur__footer-socials img {
    margin-right: 22px;
}

.guur__footer-socials img:last-child {
    margin-right: 0;
}

.guur__footer-social_style.twitter-icon {
    width: 23.76px !important;
    height: 20.81px !important;
  }
  
.guur__footer-social_style.instagram-icon {
    width: 22.68px !important;
    height: 23px !important;
}

.guur__footer-social_style.facebook-icon {
    width: 22.68px !important;
    height: 23px !important;
}

.guur__footer-social_style.linkedin-icon {
    width: 25px !important;
    height: 23px !important;
}
  
.guur__footer-logo  {
    margin-left: 63px;
    margin-right: 63px;
    width: 167px;
    height: 53px;
}

.guur__footer-logo:hover {
    filter: brightness(1.3);
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .guur__footer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
    }

    .guur__footer-socials {
        margin-bottom: 25px;
    }
}


@media screen and (max-width: 550px) {
    .guur__footer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
    }

    .guur__footer-links {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
        align-items: center;
    }

    .guur__footer-bottom {
        font-size: 12px;
    }
}
