
.guur__header-footer {
    width: 100%;
    height: 150px;
    background-color: var(--color-bg);
    margin-top: -150px;
    border-top: solid 4px var(--color-floats);
    position: relative;
    z-index: 999;
    
}
.guur__header-content__button button {
    color: #FFFFFF;
    background: #FD4C0F;
    border-radius: 53px;
    border: 0px;
    box-shadow: 5px 4px 15px 0px rgba(0, 0, 0, 0.25);
    margin-top: -34px;
    position: absolute;
    z-index: 5;
    
}
.guur__header-content__button a {
    font-family: var(--font-family2);
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center; 
    text-align: center; 
    padding: 1rem 2.36rem;
    color: #FFFFFF;
}
.guur__header-content__button button:hover {
    transform: scale(1.05);
    filter: brightness(108%);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
}
.guur__header-content__button button {
    filter: brightness(100%);
    transition: all 0.3s ease-out;
}

/* TABLET RESPONSIVE */
@media screen and (max-width: 3840px) {
    .guur__header-content__button button {
        margin-left: 63px;
    }
    .guur__header-content__button a {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 2560px) {
    .guur__header-content__button button {
        margin-left: 63px;
    }
    .guur__header-content__button a {
        font-size: 18px;
    }
}

@media screen and (max-width: 1280px) {

    .guur__header-footer{
        top: 40px;
    }
    .guur__header-content__button button {
        margin-left: 63px;
    }
    .guur__header-content__button a {
        font-size: 16px;
    }
}

@media screen and ( max-width: 1170px) {
    .guur__header-footer{
        top: 10px;
    }
}

/* TABLET RESPONSIVE */
@media screen and (max-width: 1025px) {
    .guur__header-footer{
        top: 10px;
    }
    
    .guur__header-content__button button {
        margin-top: -500px;
        margin-left: auto;
        left: 30%;
        right: 30%;
    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 890px) {

    .guur__header-content__button button {
        margin-top: -550px;

    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 780px) {

    .guur__header-content__button button {
        margin-top: -500px;

    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 700px) {

    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 610px) {
    .guur__header-content__button button {
        margin-top: -460px;

    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 550px) {
    .guur__header-content__button button {
        margin-top: -400px;

    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
/* MOBILE RESPONSIVE */
@media screen and (max-width: 430px) {

    .guur__header-content__button button {
        margin-top: -290px;

    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 415px) {

    .guur__header-content__button button {
        margin-top: -250px;
        left: 28%;
        right: 28%;
    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 398px) {

    .guur__header-content__button button {
        margin-top: -270px;
        left: 25%;
        right: 25%;
    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 350px) {

    .guur__header-content__button button {
        margin-top: -240px;
        left: 22%;
        right: 22%;
    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
@media screen and (max-width: 330px) {

    .guur__header-content__button button {
        margin-top: -210px;
        left: 22%;
        right: 22%;
    }
    .guur__header-content__button a {
        font-size: 14px;
    }
}
