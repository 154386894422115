.guur__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.9rem 4rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    margin: 1% 1% auto;
    border-radius: 60px;
}

/* .guur__navbar-scrolled {
    background-color: #284292;
    border-bottom: 3px solid #2C4AA6;
} */

.guur__navbar-scrolled {
    background-color: hsla(224, 51%, 36%, 0.7);
    backdrop-filter: blur(22px) saturate(1.8);
    border-bottom: 2px solid hsla(224, 78%, 45%, 0.7);
    border-radius: 60px;
    margin: 1% 1% auto;
} 



.guur__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.guur__navbar-links-logo img {
    width: 167px;
    height: auto;
}

r {
    filter: brightness(1.3);
    cursor: pointer;
}

.guur__navbar-links_container {
    display:flex;
    flex-direction: row;
    margin: auto;
    gap: 50px;
}

.guur__navbar-links_container a {
    text-decoration: none;
    transition: border-bottom 0.1s ease-in;
  }

.guur__navbar-menu_container-links p:hover {
    color: rgba(212, 33, 9);
    cursor: pointer;
    transition: color 0.3s ease-out;
}

.guur__navbar-dropdown_button1,
.guur__navbar-dropdown_button2 {
    padding: 1rem 1.2rem;
    color: #fff;
    background: var(--color-floats-2);
    font-family: var(--font-family2);
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;
    border-radius: 53px;
    border: 0;
    outline: none;
    cursor: pointer;
}

a.guur__navbar-dropdown_button1:hover,
a.guur__navbar-dropdown_button2:hover {
    transform: scale(1.05);
    filter: brightness(115%);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
}

a.guur__navbar-dropdown_button1,
a.guur__navbar-dropdown_button2 {
    filter: brightness(100%);
    transition: all 0.3s ease-out;
}

.guur__hamburger-toggler {
    background-color: red;
    width: 100vh;
    height: 100vh;
}

.guur__navbar-dropdown_button1{
    margin-top: 20px;
    margin-bottom: 30px;
}

.guur__navbar-links_container a:hover {
    text-decoration: none;
    border-bottom: 4px solid #FD4C0F;
    border-radius: 3px;
    padding-bottom: 12px;
  }

.guur__navbar-menu_container-links {
    text-align: center;
    margin: auto;
}

.guur__navbar-links_container p,
.guur__navbar-sign p,
.guur__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family2);
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 1rem;
    cursor: pointer;
}
 
.guur__navbar-sign button,
.guur__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: var(--color-floats-2);
    font-family: var(--font-family2);
    font-weight: 700;
    line-height: 24px;
    font-size: 1.25rem;
    border-radius: 53px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.guur__navbar-menu {
    margin-left: 1rem;
    position: relative;
    display:none;
}

.guur__navbar-menu svg {
    cursor: pointer;
}

.guur__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-blob);
    padding: 2rem 2rem 135px 2rem;
    position: absolute;
    top:40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 2px solid #a0a9ff;
}


.guur__navbar-menu_container p {
    margin: 1rem 0;

}

.guur__navbar-menu_container-links-sign {
    display:none;

}

.guur__navbar-sign {
    display:flex ;
    justify-content: flex-end;
    align-items: center;
    transition: filter 300ms;
}


.teddy {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 130px;
    height: 130px;
    filter: saturate(2);
    z-index: -1;
}

/* GUUR NAVBAR SIGN BUTTON  */

.guur__navbar-sign_dropdown {
    font-family: var(--font-family2);
    position: absolute;
    top: 100px;
    right: 68px;
    background-color: #5E6DC2;
    color: #fff;
    border-radius: 5px;
    border: 2px solid #a0a9ff;
    padding: auto 10%;
    width: 20%;
    height: 360px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.guur__navbar-sign_dropdown li {
    margin-bottom: 50px;
}

.guur__navbar-sign_dropdown ul {
    margin-top: 30px;
}

.guur__navbar-sign_dropdownItem {
    font-family: var(--font-family2);
    font-size: 1rem;
    transition: all 0.2s ease;
}

.guur__dropdown-item {
    display: flex;
    margin: 10px auto;
}
.guur__dropdown-item a {
    max-width: 100px;
    margin-left: 10px;
    transition: var(--speed);
}


.guur__navbar-sign_dropdown ul li:hover a{
    color: #FD4C0F;
    cursor: pointer;
    transition: all 0.2s ease;
}

.guur__navbar-sign_button {
    transition: all 0.3s ease-out;
}


.guur__navbar-sign_button:hover {
    transform: scale(1.05);
    filter: brightness(115%);
    transition: all 0.3s ease-out;
}

.guur__navbar-sign_dropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
    transition: var(--speed) ease;
}

.guur__navbar-sign_dropdown.inactive {
    visibility: hidden;
    opacity: 0;
    transform: translate(-60px);
    transition: var(--speed) ease;
}


/* MEDIA SCREEN MOBILE RESPONSIVE WEBSITE */

@media screen and (max-width: 1250px) {
    .guur__navbar-links_container {
        display: none;
    }

    .guur__navbar-menu {
        display: flex;
    }

    .guur__navbar-dropdown_button1 {
        display: none;
    }

    .guur__navbar-dropdown_button2 {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .guur__navbar-sign_dropdown {
        height: 280px;
        width: 200px;
    }
}

@media screen and (max-width: 700px) {
    .guur__navbar {
        padding: 2rem 4rem;
    }    
}

@media screen and (max-width: 550px) {
    .guur__navbar {
        padding: 2rem;
    }

    .guur__navbar-sign {
        display: none;
    }

    .guur__navbar-dropdown_button1 {
        display: flex;
        
    }

    .guur__navbar-dropdown_button2 {
        display: flex;
    }

    .guur__navbar-menu_container {
        top: 20px;
    }

    .guur__navbar-sign_dropdown {
        display: none;
    }

}

/* TABLET RESPONSIVE */

@media screen and (max-width: 1025px) {
    .guur__navbar-links-logo img {
        margin-left: 30px;
    }

    .guur__navbar {
        padding: 20px 0px;
    }

    .guur__navbar-menu {
        margin-right: 30px;
    }
}



/* MOBILE RESPONSIVE */

@media screen and (max-width: 430px) {
    .guur__navbar-links-logo img {
        width: 130px;
        height: auto;
        margin-left: 22px;
    }

    .guur__navbar {
        padding: 20px 0px;
    }

    .guur__navbar-menu {
        margin-right: 22px;
    }
}


@media screen and (max-width: 380px) {
    .guur__navbar-links-logo img {
        width: 110px;
        height: auto;
        margin-left: 20px;

    }

    .guur__navbar-menu {
        margin-right: 20px;
    }
}

@media screen and (max-width: 350px) {
    .guur__navbar-links-logo img {
        width: 90px;
        height: auto;
        margin-left: 16px;

    }

    .guur__navbar-menu {
        margin-right: 16px;
    }
}
